import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import config from "../../config";
import Footer from "../section/footer";
import MFAOption from "./mfa"
import { Login } from "@slbnsc/react-portal";
import logoBrand from "../../images/dayman/dayman logo.png";
import RegisterForm from "../section/register";
import ForgotPassword from "../forgot-password/index";

const  LoginPage = (props) =>{
  const [register, setRegister] = useState(false);
  const [fullHeight, setFullHeight] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [mfa, setMfa] = useState(null);

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }

  return (

    <Container fluid className={`fh no-gutters login-main-body login-page ${fullHeight ? "vh-page" : ""} `}>
      <Container>
        <header className="login-main-logo">
          <a href="https://www.daymanapartments.com.au/" target="_blank">
            <img src={logoBrand} className="" alt="brand-logo"/>
          </a>
        </header>
      </Container>
      <Row className="fh-element align-items-center">
        <Col>
          {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
          {register &&
            <Container className="mb-5">
              <Container>
                <RegisterForm setFullHeight={async (data) => setFullHeight(data)} backToLogin={async () => setRegister(false)} />
              </Container>
            </Container>
          }
          {!register && <Container>
            <Row>
              {!mfa && ( <Col lg={6} className="mb-5 mb-lg-0">
                <div className="login-section-container">
                  <div className="login-container">
                    <p className="login-title">
                      {"Login to your account"}
                    </p>
                  
                    <Login
                      inputClassName={"login-form-input"}
                      noLabels={false}
                      labelClassName={"form-input-label"}
                      authUrl={config.auth} 
                      portalUrl={config.portal} 
                      apiUrl={config.backend}
                      noPlaceHolders={true}
                      floating={true}
                      inline={true}
                      withUserIcon={true}
                      loginBtnLabel={"Login"}
                      withPasswordIcon={true}
                      withRecoverPasswordLink={true}
                      setMfa={setMfa} 
                      recoverPasswordLink={<>
                        <a className="forgot-password" onClick={handleClickForgotPassword}>{"Recover Password"}</a>
                      </>}
                    />
                  </div>
                </div>
              </Col>)}
              <Modal
                show={mfa}
                className="login-mfa-panel-modal"
                onHide={()=> {}}
              >
                <MFAOption mfa={mfa} setMfa={setMfa} authUrl={config.auth} portalUrl={config.portal} apiUrl={config.backend}/>
              </Modal>
              <Col lg={6} className="mb-5 mb-lg-0 login-brand-container">
                <Container>
                  <div className="login-heading-banner d-none d-md-block mb-5">
                    <p className="banner-heading">{"dayman"}<br/>
                      <span className="banner-subheading">{"apartments"}</span>
                    </p>

                    <p className="banner-subtitle">{"You Will Be MOVING"}<br/>
                      {"in the Right Direction with Us"}
                    </p>
                  </div>
                </Container>
              </Col>
            </Row>
          </Container>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer />
        </Col>
      </Row>
    </Container>

  );
}


export default LoginPage;

