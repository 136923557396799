import React from 'react'
import Loader from '../bootstrap/loader';
import { useQuery } from '@apollo/client';
import { getCurrentUserQuery } from '../logic/user';
import LoginPage from './login-page';

const UserCheck = (props) =>{
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (user) {
    window.location = "/portal";
  }

  return (
    <LoginPage {...props} />
  );
}


export default UserCheck;
