import React, { useContext } from 'react'
import { Button, Container } from 'react-bootstrap';
import moment from "moment-mini";

import OtpInput from '../../../otp-input';
import { characterHide, mfaContext } from '..';

const SMSPanel = () => {
  const {
    user,
    loading,
    otp,
    setOtp,
    setOtpError,
    inputFocus,
    setInputFocus,
    otpError,
    requestCodeVerified,
    retryEnabled,
    retryTimer,
    handleRetry,
    setMfaMethod,
    stopPolling,
    setRetryTimer,
  } = useContext(mfaContext);

  return (
    <Container>
      <div className="title mb-2">{"Enter your code"}</div>
      <p className="mfa-sub-heading text-justify">
        {loading ? (
          <i className="fad fa-spinner" />
        ) : (
          <span>
            {
              "An sms has been sent to your registered mobile number that ends with "
            }
            <b>{characterHide(user?.mobileNumber)}</b>
          </span>
        )}
      </p>
      <p className="mfa-sub-heading text-justify">
        {loading ? (
          <i className="fad fa-spinner" />
        ) : (
          <span>
            <b>{"Please click the approval link provided"}</b>
            {" or enter the code below to continue."}
          </span>
        )}
      </p>
      {loading ? (
        <i className="fad fa-spinner" />
      ) : (
        <OtpInput
          count={6}
          value={otp}
          onChange={(value) => {
            setOtp(value);
            setOtpError(null);
          }}
          inputFocus={inputFocus}
          setInputFocus={setInputFocus}
        />
      )}
      {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
      <div className="retry-container">
        {!requestCodeVerified && retryEnabled && (
          <div className="retry-timer">{"Request code expired"}</div>
        )}
        {!requestCodeVerified && !retryEnabled && retryTimer > 0 && (
          <div className="retry-timer">
            {`expires in ${
              retryTimer > 60
                ? moment.utc(retryTimer * 1000).format("mm:ss")
                : `${retryTimer} ${retryTimer > 1 ? "seconds" : "second"}`
            }`}
          </div>
        )}
        {!requestCodeVerified && retryEnabled && (
          <Button className="vw-button btn-login ml-0" onClick={handleRetry}>
            {"Retry"}
          </Button>
        )}
      </div>
      <div className="mfa-other-options-container">
        <span
          onClick={() => {
            setMfaMethod(null);
            stopPolling();
            setRetryTimer(0);
          }}
        >
          {"Use another method to receive the code"}
        </span>
        <span onClick={() => (window.location = "/login")}>
          {"Sign in to a different account"}
        </span>
      </div>
    </Container>
  );
};

export default SMSPanel