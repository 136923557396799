import { gql } from "@apollo/client";

export const createVerificationMutation = gql`
mutation createVerification (
  $type: verificationType
  $userId: String
  $name: String
  $template: String
) {
    classMethods {
      Verification {
        createVerification (
          type: $type
          userId: $userId
          name: $name
          template: $template
        ) {
          id
          valid
        }
      }
    }
  }
`;

export const validateVerification = gql`
  query validateVerification (
    $requestCode: String
  ) {
    classMethods {
      Verification {
        validateVerification (
          requestCode: $requestCode
        )
      }
    }
  }
`;

export const resendVerificationMutation = gql`
  mutation resendVerification (
    $requestCode: String
  ) {
    classMethods {
      Verification {
        resendVerification (
          requestCode: $requestCode
        )
      }
    }
  }
`;

export const generateTOTPMutation = gql`
mutation generateTOTP(
  $id: ID
){
  classMethods { 
    UserAuth {
        generateTOTP (id: $id) {
            otpAuthURL
            secret
            userAuthId
            }
        }
  }
}
`

export const removeUserTOTPMutation = gql`
mutation removeUserTOTP {
  classMethods {
    UserAuth {
      removeTOTP 
    }
  }
}
`;
export const getAdminGeneratedVerification = gql`
  query getAdminGeneratedVerification ($userId: String) {
    classMethods {
      Verification {
        adminGeneratedVerification(
          userId: $userId
        ) {
          id
          valid
          consumed
        }
      }
    }
  }
`;

export const denyVerificationMutation = gql`
  mutation denyVerification (
    $requestCode: String
  ) {
      classMethods {
        Verification {
          denyVerification(
            requestCode: $requestCode
          )
        }
      }
    }
`;