import React, { useContext, useState } from 'react'
import { Button, Container } from 'react-bootstrap';

import OtpInput from '../../../otp-input';
import { mfaContext } from '..';

const MFAAdminGeneratedPanel = () => {
  const {
    user,
    loading,
    inputFocus,
    setInputFocus,
    requestCodeVerified,
    retryEnabled,
    retryTimer,
    handleRetry,
    setMfaMethod,
    activeVerification,
    stopPolling,
    setRetryTimer,
  } = useContext(mfaContext);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false);

  if (!loading && !activeVerification && activeVerification === null) {
    return (
      <div className="mfa-panel admin-generated-container">
        <p className="admin-generated-message">
          {
            "Oh no! It seems like there are no available one-time password at the moment."
          }
        </p>
        <Button
          className="cancel-request-btn"
          onClick={() => {
            window.location = "/login";
            // setShowMfa(false);
            // setMfaMethod(null);
          }}
          disabled={loading}
        >
          {"Back to Login"}
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (activeVerification && otp.join("").length === 6) {
      handleOtpSubmit(otp.join(""));
    }
  }, [otp]);

  const handleOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const apiPath = process.env.BACKEND_URL;
    const data = await fetch(
      `${apiPath}rest.api/verify/${activeVerification.id}:${otp}`,
      {
        method: "GET",
      }
    );

    const parsedData = await data.json();

    if (parsedData.success) {
      handleApprove(activeVerification.id);
    } else {
      setOtpError("Invalid code");
    }

    setOtpLoading(false);
  };

  return (
    <div className="mfa-panel">
      <div className="title mb-2">{"Enter your code"}</div>
      <p className="mfa-sub-heading text-justify">
        {loading ? (
          <i className="fad fa-spinner" />
        ) : (
          "You have received a one-time-passcode from the admin."
        )}
      </p>
      <p className="mfa-sub-heading text-justify">
        {loading ? (
          <i className="fad fa-spinner" />
        ) : (
          "Enter the code below to continue."
        )}
      </p>
      <OtpInput
        count={6}
        value={otp}
        onChange={(value) => {
          setOtp(value);
          setOtpError(null);
        }}
      />
      {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
      <div className="mfa-other-options-container">
        <span onClick={() => setMfaMethod(null)}>
          {"Use another method to receive the code"}
        </span>
        <span
          onClick={() => {
            window.location = "/login";
          }}
        >
          {"Sign in to a different account"}
        </span>
      </div>
    </div>
  );
};

export default MFAAdminGeneratedPanel;
