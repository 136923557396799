import { gql } from "@apollo/client";

export const getFindBuildingResult = ({data}) => data?.classMethods?.Building?.findByFormattedAddress || [];
export const findBuildingQuery = gql`
  query getBuildingByFormattedAddress ($address: String) {
    classMethods {
      Building {
        findByFormattedAddress (address: $address) {
          id
          name
          code
          notification
          ewayCountry
          formattedAddress
          address {
            id
            streetNo
            address
            suburb
            state
            postCode
            countryId
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const getAddressQuery = gql`
  query getAddress ($id: ID!) {
    models {
      Address (where: {
        id: {eq: $id}
      }) {
        edges {
          node {
            id
            streetNo
            address
            suburb
            state
            postCode
          }
        }
      }
    }
  }
`;
