import React, { Fragment } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";

import impressiveMeter from "../../images/plan/impressive.svg";
import { getPageInfoQuery } from "../logic/general";
import { getProductsResult } from "../logic/products";

export default function ProductSelector({ onSelectProduct, buildingCode }) {
  const pageInfoQuery = useQuery(getPageInfoQuery);

  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  const products = getProductsResult(pageInfoQuery, {variables: {buildingCode}});

  return (
    <Carousel
      interval={null}
      nextIcon={<i className="fas fa-angle-right carousel-control" />}
      prevIcon={<i className="fas fa-angle-left carousel-control" />}
    >
      {(products || []).map((product) => {
        const [wholeNumber = "00", decimal = "00"] = `${
          product?.value || ""
        }`.split(".");
        return (
          <Carousel.Item key={product?.id}>
            <Col xs={10} sm={8} lg={6} xl={4} className="d-block mx-auto">
              <button
                className="p-0 mb-1 mx-auto mt-0 border-0 d-block"
                onClick={async () => {
                  await onSelectProduct(product);
                }}
              >
                <div className="plan-item text-center">
                  <img
                    src={impressiveMeter}
                    alt="not so basic meter"
                    className="plan-meter"
                  />
                  <div className="plan-title font-cyan font-primary-bold">
                    {product.name || ""}
                  </div>
                  <div className="plan-subtitle font-white">
                    {product.description || ""}
                  </div>
                  <div className="plan-price font-white">
                    <span className="font-orange price-value">
                      {wholeNumber}
                    </span>
                    <span className="small-price font-orange">
                      {`.${decimal}`}
                    </span>
                    <span className="font-white price-freq">{"/month"}</span>
                  </div>
                  <div className="plan-rate font-cyan mb-3">
                    {product.rateProfile || ""}
                  </div>
                  <Button variant="orange" className="btn-wide">
                    {"Get it"}
                  </Button>
                </div>
              </button>
            </Col>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
