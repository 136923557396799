import React, { Fragment, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Formsy from "formsy-react";
import {
  Alert,
  Button,
  FormGroup,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { parsePhoneNumber } from "react-phone-number-input";
import copy from "copy-to-clipboard";

import Input from "../bootstrap/input";
import Select from "../bootstrap/select";
import PhoneInput from "../bootstrap/phone-input";

import Captcha from "../bootstrap/captcha";

import {
  getPageInfoQuery,
  getEwayClientKey,
  getBuilding,
} from "../logic/general";
import {
  createMobileVerificationMutation,
  createMobileVerificationResult,
  resendVerificationMutation,
} from "../logic/mobile";
import {
  purchaseAndCreateUserMutation,
  getPurchaseResult,
} from "../logic/purchase";

function FieldTemplate(props) {
  return (
    <Col md={6}>
      <FormGroup>{props.children}</FormGroup>
    </Col>
  );
}

export default function ProductPurchase({ building, product, onSuccess }) {
  const [userData, setUserData] = useState(false);
  const [isResend, setResend] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [purchaseError, setPurchaseError] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);

  const [cmvMutation] = useMutation(createMobileVerificationMutation);
  const [rmvMutation] = useMutation(resendVerificationMutation);
  const [purchaseMutation] = useMutation(purchaseAndCreateUserMutation);
  const pageInfoQuery = useQuery(getPageInfoQuery);

  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  const ewayKey = getEwayClientKey(pageInfoQuery);

  function handleCopyPassword(password) {
    return copy(password, {
      debug: true,
      message: "Press #{key} to copy",
    });
  }

  async function handleValidSubmit(input) {
    try {
      await setProcessing(true);
      const response = await cmvMutation({
        variables: {
          mobileNumber: input.mobile,
          captcha: input.captcha,
          captchaKey: input.captchaKey,
        },
        awaitRefetchQueries: true,
      });
      if (createMobileVerificationResult(response)) {
        setCaptchaError(null);
        setUserData(input);
        return setProcessing(false);
      } else {
        //TODO: present failure message
        await setProcessing(false);
      }
    } catch (err) {
      console.log({ err });
      if (err.length) {
        await setProcessing(false);
        return setCaptchaError(err[0].message);
      }

      await setProcessing(false);
      return setCaptchaError("Invalid captcha");
    }
    await setProcessing(false);
  }

  function handleCloseModal() {
    setUserData(null);
  }

  async function handlePurchase(code) {
    console.log("PURCHASE", userData);
    try {
      const data = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        mobileNumber: userData.mobile,
        mobileCountry: "au",
        voucherCode: userData.voucherCode,
        verificationCode: code,
        streetNo: building.address.streetNo,
        address: building.address.address,
        suburb: building.address.suburb,
        postCode: building.address.postCode,
        state: building.address.state,
        building: userData.unitNo,
        countryId: building.address.country.id,
        unitNo: userData.unitNo,
        country: "au",
        buildingCode: building.code,
        productId: product?.id,
        cardName: userData.cardHolderName,
        cardNumber: window.eCrypt.encryptValue(userData.cardNumber, ewayKey),
        cardCCV: window.eCrypt.encryptValue(userData.cardCCV, ewayKey),
        cardExpiry: `${userData.cardExpiryMonth}${userData.cardExpiryYear}`,
      };

      const response = await purchaseMutation({
        variables: {
          ...data,
        },
      });

      console.log("RES >>> ", response);
      const result = getPurchaseResult(response);
      console.log("result >>> ", result);

      if ((result || {}).id) {
        await setProcessing(false);
        await setCredentials(result);
        await setUserData(null);
        await setPurchaseError(null);
        return setShowSuccessModal(true);
      }
    } catch (err) {
      console.log(
        "err",
        typeof err,
        Object.keys(err),
        err.hasOwnProperty("error")
      );

      await setProcessing(false);
      return setPurchaseError(err.message);
    }
  }

  return (
    <>
      {showSuccessModal && (
        <Modal show onHide={async() => {
          await setShowSuccessModal(false);
          return onSuccess && onSuccess();
        }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Purchase Success"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col xs={3}>{"Order ID: "}</Col>
                <Col>{credentials.id}</Col>
              </Row>
              <Row>
                <Col xs={3}>{"Username"}</Col>
                <Col>{credentials.userName}</Col>
              </Row>
              <Row>
                <Col xs={3}>{"Password"}</Col>
                <Col>
                  {credentials?.newPassword}
                  <Button
                    variant="light px-1 py-0 ml-1"
                    onClick={() => {
                      handleCopyPassword(credentials?.newPassword);
                    }}
                  >
                    <i className="fas fa-copy" />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="darkblue"
              onClick={async () => {
                await setShowSuccessModal(false);
                return onSuccess && onSuccess();
              }}
            >
              {"Back to Login"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {!!userData ? (
        <>
          {purchaseError && (
            <Modal show onHide={() => setPurchaseError(null)}>
              <Modal.Header>
                <Modal.Title>{"An error has occured"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>{purchaseError}</Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="darkblue"
                  onClick={() => setPurchaseError(null)}
                >
                  {"Close"}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <Modal show onHide={handleCloseModal}>
            <Formsy
              onValidSubmit={async (data) => {
                await setProcessing(true);
                return handlePurchase(data.code);
              }}
              onInvalidSubmit={(e) => {
                console.log("error", e);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>{"Confirm your mobile number"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>
                      {
                        "We have sent a text message to your phone, please enter the code below"
                      }
                    </Col>
                  </Row>
                  <Row className="mt-20">
                    <Col>
                      <Input
                        name="code"
                        label="Mobile Verification Code"
                        defaultValue=""
                        disabled={isProcessing || purchaseError !== null}
                        required
                      />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  disabled={isResend || isProcessing || purchaseError !== null}
                  onClick={async () => {
                    await setResend(true);
                    await rmvMutation({
                      variables: {
                        mobileNumber: userData.mobile,
                      },
                    });

                    return setTimeout(() => {
                      return setResend(false);
                    }, 2000);
                  }}
                >
                  {isResend ? "Success" : "Resend"}
                </Button>
                <Button
                  type="submit"
                  disabled={isProcessing || purchaseError !== null}
                  variant="darkblue"
                >
                  {isProcessing ? "Please wait ... " : "Submit"}
                </Button>
              </Modal.Footer>
            </Formsy>
          </Modal>
        </>
      ) : undefined}
      <Formsy
        onValidSubmit={handleValidSubmit}
        onInvalidSubmit={(e) => {
          console.log("error", e);
        }}
      >
        <Container className="mt-3">
          {/* <Row>
            <FieldTemplate>
              <label htmlFor="inpBuildingCode">Building Code</label>
              <input
                type="text"
                readOnly
                className="form-control"
                name="buildingCode"
                id="inpBuildingCode"
                value={buildingCode || ""}
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate>
              <Select
                name="product"
                label="Product"
                required
                defaultValue={products[0]?.id}
              >
                {products.map((product) => {
                  return (
                    <option
                      key={product.id}
                      value={product.id}
                    >{`${product.name} - ${product.description}`}</option>
                  );
                })}
              </Select>
            </FieldTemplate>
          </Row> */}
          <Row>
            <FieldTemplate>
              <Input
                name="firstName"
                label="First Name"
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="lastName"
                label="Last Name"
                defaultValue=""
                required
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate>
              <Input name="unitNo" label="Unit No" defaultValue="" />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="streetNo"
                label="Street"
                defaultValue=""
                disabled
                value={`${building?.address?.streetNo} ${building?.address?.address}`}
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate>
              <Input
                name="suburb"
                type="text"
                label="City"
                value={`${building?.address?.suburb}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="state"
                type="text"
                label="State"
                value={`${building?.address?.state}`}
                disabled
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate>
              <Input
                name="country"
                type="text"
                label="Country"
                value={`${building?.address?.country?.name}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="suburb"
                type="text"
                label="Postal Code"
                value={`${building?.address?.postCode}`}
                disabled
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate>
              <Input
                type="email"
                name="email"
                label="Email Address"
                validations={{
                  isEmail: true,
                  minLength: 1,
                }}
                validationErrors={{
                  isEmail: "Email address is not valid",
                  minLength: "",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="mobile"
                label="Mobile Number"
                placeholder="Mobile Number"
                type="number"
                defaultValue=""
                required
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate>
              <Input
                name="cardHolderName"
                label="Card Holder Name"
                validations={{
                  maxLength: 50,
                  isExisty: true,
                  matchRegexp: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
                }}
                validationErrors={{
                  isExisty: "This field is required",
                  maxLength: "You can not type in more than 50 characters",
                  matchRegexp: "Enter the name on your credit card",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="cardNumber"
                label="Card Number"
                validations={{
                  maxLength: 16,
                  minLength: 16,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "This field is required",
                  maxLength: "You can not type in more than 16 characters",
                  minLength: "You can not type in less than 16 characters",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate>
              <Input
                name="cardExpiryMonth"
                label="Card Expiry Month"
                validations={{
                  maxLength: 2,
                  minLength: 2,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  minLength: "You can not type in less than 2 characters",
                  maxLength: "You can not type in more than 2 characters",
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "This field is required",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="cardExpiryYear"
                label="Card Expiry Year"
                validations={{
                  maxLength: 2,
                  minLength: 2,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  minLength: "You can not type in less than 2 characters",
                  maxLength: "You can not type in more than 2 characters",
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "This field is required",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate>
              <Input
                name="cardCCV"
                label="CCV"
                defaultValue=""
                validations={{
                  minLength: 3,
                  maxLength: 3,
                  isExisty: true,
                  isNumeric: true,
                }}
                validationErrors={{
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "CCV field is required",
                  minLength: "You can not type in less than 3 characters",
                  maxLength: "You can not type in more than 2 characters",
                }}
                required
              />
            </FieldTemplate>
          </Row>
          <Row>
            <Col xs="6">
              <Captcha />
            </Col>
            {captchaError && (
              <Col xs="6">
                <Alert className="mt-20" variant="danger">
                  {captchaError}
                </Alert>
              </Col>
            )}
          </Row>
          <Row className="mt-2 mb-2">
            <Col xs="auto" className="ml-auto">
              <Button type="submit" variant="orange" disabled={isProcessing}>
                {"Purchase"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Formsy>
    </>
  );
}
