import React, { useRef, useEffect, Fragment, useState } from "react";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";

import Purchase from "./purchase";
import ProductSelector from "../bootstrap/product-selector";
import AddressLocator from "../address-selector";

export default function RegisterForm({ backToLogin, setFullHeight }) {
  const myRef = useRef(null);
  const [selectedProduct, setProduct] = useAsyncSetState(null);
  const [selectedBuilding, setBuilding] = useAsyncSetState(null);
  const [step, setStep] = useAsyncSetState({
    addressSelector: true,
    productSelector: false,
    purchase: false,
  });

  const [loading, setLoading] = useAsyncSetState(false); 

  return (
    <Container className={`register-purchase ${step.addressSelector ? "address-step" : ""}`}>
      <div className="plan-section" ref={myRef}>
        <Container>
          <Row>
            <Col>
              {backToLogin && (
                <Button
                  variant="black-text"
                  onClick={async () => {
                    if (backToLogin) {
                      await backToLogin();
                    }
                    if (setFullHeight) {
                      await setFullHeight(true);
                    }
                  }}
                >
                  <i className="fas fa-arrow-left mr-2" />
                  {"Back to login"}
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center main-text font-orange">
                {`Register - ${(step.addressSelector && "Enter your address") || (step.productSelector && "Select your plan") || (step.purchase && "Enter your Details") || ""}`}
              </div>
            </Col>
          </Row>

          {loading && <div className="loader" />}

          {step.addressSelector && (
            <Row className="mb-5">
              <Col className="vw-sup-locator">
                <AddressLocator
                  setBuilding={async (data) => {
                    await setLoading(true);
                    await setStep({
                      productSelector: true,
                      addressSelector: false,
                      purchase: false,
                    });
                    await setLoading(false);
                    if (setFullHeight) {
                      await setFullHeight(false);
                    }
                    return setBuilding(data);
                  }}
                  inline
                  isWhite
                  highlight
                  showLocator
                />
              </Col>
            </Row>
          )}
          {step.productSelector && (
            <Row>
              <Col xs={12}>
                <ProductSelector
                  buildingCode={selectedBuilding?.code}
                  onSelectProduct={async (product) => {
                    await setLoading(true);
                    await setStep({
                      productSelector: false,
                      addressSelector: false,
                      purchase: true,
                    });
                    await setLoading(false);
                    if (setFullHeight) {
                      await setFullHeight(false);
                    }
                    return setProduct(product);
                  }}
                />
              </Col>
            </Row>
          )}

          {step.purchase && (
            <Purchase
              building={selectedBuilding}
              product={selectedProduct}
              selectProduct={(data) => setProduct(data)}
              onSuccess={async () => {
                // await setLoading(true);
                if (setFullHeight) {
                  await setFullHeight(true);
                }
                if (backToLogin) {
                  await backToLogin();
                }
                // await setStep({
                //   productSelector: false,
                //   addressSelector: true,
                //   purchase: false,
                // });

                // return setLoading(false);

              }}
            />
          )}
        </Container>
      </div>
    </Container>
  );
}
