import React, { useEffect } from "react";

import { withFormsy } from "formsy-react";
function FormsyHidden(props) {
  const { val } = props;
  useEffect(() => {
    props.setValue(val);
  }, [val, props]);
  return <input name={props.name} type="hidden" value={props.value} />;
}

export default withFormsy(FormsyHidden);
