import React, { useEffect } from "react";
import { withFormsy } from "formsy-react";
import { Col, Row } from "react-bootstrap";

function SelectField(props) {
  useEffect(() => {
    if (props.setValue) {
      if (props.defaultValue && !props.value) {
        props.setValue(props.defaultValue);
      } else if (!props.value) {
        props.setValue("");
      }
    }
  }, [props]);
  let labelText;
  if (props.label) {
    labelText = props.label;
  }
  let label = props.label ? (
    <label style={props.noLabel ? { color: "#fff" } : {}}>{labelText}</label>
  ) : undefined;
  const field = (
    <div className="input-group select ">
      <div className="placeholder" />
      <select
        className="form-control"
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
      >
        {(props.options || []).map((option) => {
          return (
            <option
              key={option.id}
              value={option.value}
            >
              {option.text}
            </option>
          );
        })}
      </select>
    </div>
  );
  if (props.inline) {
    return (
      <Row className="align-items-center inline">
        {props.label ? <label className="col-3">{labelText}</label> : undefined}
        <Col>
          {field}
          {props.afterField}
        </Col>
      </Row>
    );
  }
  return (
    <React.Fragment>
      {label}
      {field}
    </React.Fragment>
  );
}

export default withFormsy(SelectField);
