import store from "store";
import expirePlugin from "store/plugins/expire";
store.addPlugin(expirePlugin);

const storeKey = "form-data";
const formExpiryDuration = (1000 * 60 * 60); // 1 Hour
export function getFormData() {
  return store.get(storeKey) || {};
}
export function setFormData(data) {
  store.set(storeKey, Object.assign(getFormData(), data), new Date().getTime() + formExpiryDuration);
}
export function clearFormData() {
  store.set(storeKey, {});
}